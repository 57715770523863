.sem_BlueprintProjectListTab {

  .new-project-modal {
    .C-Modal__scrollContainer {
      overflow: visible;

      .C-Modal__container {
        width: 30vw;

        .sem_ModalCloseBtn {
          margin: -8px -8px;
        }
      }
    }
  }
}

.table-editor-modal {
  .C-Modal__container {
    min-width: 96vw;
  }
}
