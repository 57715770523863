.filterCard {
  margin-top: 0;
  border-radius: 4px;
  background-color: #eee;

  &.C-ExpandableSection.\--xs-padding-dense {
    .C-ExpandableSection__TopRow {
      text-transform: uppercase;
    }

    .C-ExpandableSection__Toggle__Button {
      color: #366cd9;
    }
  }

  .hc-clr-interaction {
    color: #366cd9;
  }

  .C-Divider {
    padding: 0 8px 8px 8px;
  }

  .C-Button.\--outlined {
    line-height: 24px;
    border-width: 1px;
    background: #fff;

    &:hover {
      background: #366cd933;
    }
  }
}

.sem_SignLibraryFilterChip {
  margin: 0 9px 9px 0 !important;
  background: #ffa000;
  color: #fff;
  border: none;

  .C-Chip__children {
    display: inherit;
  }

  .sem_FilterChip--filter{
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .sem_FilterChip--value {
    font-weight: 700;
    letter-spacing: 0;
    text-transform: none;
  }

  .C-Button:hover {
    border: 1px solid #ffa000;
    background: #fff;
    color: #ffa000;
  }
}

.sign-library-table,
.sign-import-table {
  .ag-standard-button {
    &.ag-filter-apply-panel-button {
      display: inline-flex;
      align-items: center;
      padding: 0 12px;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
  }

  .C-GridContainer.\--spacing-normal {
    &.sem_StandardForm {
      &.error_messaging_enabled {
        &.wide-form {
          width: 400px;
        }
      }
    }
  }

  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: 40px;
  }

  .ag-details-row {
    padding: 8px;

    .ag-details-grid {
      border: 1px solid #d6d6d6;

      .ag-header {
        background: #f0f0f0;

        span.ag-header-cell-text {
          color: #666;
        }

        .ag-header-cell-resize::after {
          background-color: #d6d6d6;
        }
      }
    }
  }
}

.export_rail {
  padding-left: 0 !important;

  .C-FormField--Checkboxes {
    height: calc(50vh - 260px);
    overflow-y: auto;
    overflow-x: hidden;


    .C-FormField--Checkbox {
      margin: 6px 6px 6px 0;
    }
  }
}
