.sem-LeftNav {
  .sem-LeftNavHeader {
    background: #D6D6D6;
    color: #666;
    justify-content: space-between;

  }

  .sem-LeftNavItem {
    text-transform: uppercase;

    &.active {
      border-color: #366CD9;
      color: #366CD9;
    }
  }

  .sem-LeftNavItem_active {
    border-color: #366CD9;
  }

  .logout-icon { margin: 0 auto 0 8px }
}

.sem_EllipsisHeader{
  margin-left:12px;
  margin-right:12px;

}
.sem_ProjectDivision {
  font-weight: 700;
  color: #666;
  padding: 14px;
  padding-right:0px;

}

.sem_ProjectDivision--Values {
  padding: 4px;

}

.sem_ProjectDivision--Container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 12px;
}


.sem_AssethubProject {
  font-weight: 700;
  color: #666;
  padding: 14px;
  padding-right:0px;

}

.sem_AssethubProject--Values {
  padding: 4px;

}

.sem_AssethubProject--Container {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 12px;
}

.sem_CompanyName {
  color: #666;
  font-size: 12px;
  line-height: 1;
}

.C-GridContainer>.C-GridItem.sem-LeftNavToggle {
  display: inline-flex;
  position: relative;
  top: -29px;
  left: -22px;
  padding: 14px 6px 14px 6px;
  width: 48px;
  height: 64px;
  background-color: #D6D6D6;
  color: #666;
}

.sem-LeftNavItem-UserName {
  width: 210px;
}

.sem-LeftNav-ToggleButton {
  color: #666;
  &:hover, &:focus {
    background-color: #B3B3B3;
    color: #666
  }
}
.sem_CompanyName {
  color: #666;
  font-size: 12px;
  line-height: 1;
}

.sem_MainSectionBreadcrumbs {
  height: auto;
  margin: -12px 0 12px 0;
}

.sem_OverviewCard {
  background: #eee;
  color: #666;
  line-height: 24px;
}

.sem_StrongText { font-weight: 700; }



.sem-Subheading {
  padding-left: 12px;
  font-size: 20px;
  font-weight: 400;
  color: #B3B3B3;
}

.sem_IconBtn {
  color: #b3b3b3;

  &:hover {
    color: #888;
    background-color: #eee;
  }
}

.sem_StatusChip {
  min-width: 96px;
  font-weight: 400 !important;
}

.sem_IconBtn-dark {
  color: #666;

  &:hover {
    color: #333;
    background: none;
  }
}

.sem_Autocomplete {
  padding: 8px 14px!important;

  .C-FormField__Label {
    display: flex;
    font-size: 12px;
    margin: 0;
    padding: 6px 6px 6px 0;
    line-height: 1;
    color: #666;
    font-weight: 700;
  }
}

.sem_StandardForm {
  .C-FormField__Label {
    color: #666;
    font-weight: 700;
  }

  &.error_messaging_enabled {
    > .C-GridItem {
      padding-bottom: 0;
    }

    .C-FormField__FieldInfo {
      display: block;
      line-height: 1;
      margin-top: 4px;
      min-height: 12px;
      color: #118f9c;

      &.isErrored {
        color: #b85300;
      }
    }

    .C-FormField--Input.isHighlighted,
    .C-FormField--Select__Input.isHighlighted,
    .C-FormField--Textarea.isHighlighted,
    .C-FormField--Select__Input.isHighlighted+.C-FormField--Select__Background,
    .C-Autocomplete.isHighlighted {
      background: #fffbd6;
    }
  }
}

.simple_error_messaging_enabled {
  .C-FormField__FieldInfo {
    display: block;
    line-height: 1;
    margin-top: 4px;
    min-height: 12px;
  }
}

.sem_InlineForm {
  .C-FormField {
    display: flex;
    font-size: 12px;

    .C-FormField__Label {
      margin: 0;
      padding: 6px 6px 6px 0;
      line-height: 1;
      color: #666;
      font-weight: 700;
    }

    .C-FormField--Select,
    .C-FormField--Input {
      flex-grow: 1;
      width: fit-content;
    }

    .C-FormField--Input,
    .C-FormField--Select__Input {
      color: #666;
      height: 24px;
      transition: all .3s ease;

      &:hover,
      &:focus {
        border-color: #888;
      }
    }

    .C-FormField--Select__Background {
      height: 23px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .sem_InlineDatePicker {
    .C-FormField__Label.isErrored { color: #333 }
    .C-FormField--Input.isErrored { border-color: #B3B4B3; }

    .C-DatePicker__MonthIcons {
      top: 0px;

      .C-Button {
        color: #b3b4b3;

        &:hover {
          color: #224fca;
        }
      }
    }

    .C-DatePicker__Card { top: 20px; }
  }
}

.sem_bulkDatePicker .C-DatePicker__MonthIcons {
  top: 58px;
}

.sem_SignProjectDatePicker {
  .C-FormField__FieldInfo.isErrored { display: inline; }

  .C-DatePicker__Card { left: 0; }
}


.sem_SignProjectBudget {
  .C-FormField__FieldInfo.isErrored { display: inline; }
}

.sem-Table {
  .C-TableHead { text-transform: uppercase; }

  .C-TableRow { max-height: 40px;}

  .C-TableData {
    white-space: nowrap;

    &.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sem_Table--IconHeader {
    min-width: 24px;
  }

  .C-TableRow.\--clickable,
  .sem_TableCardItem {
    margin-top: 8px;
    background: #f7f7f7;
    border-left: 4px solid #f7f7f7;
    border-bottom: none;
    border-radius: 4px;
    align-items: center;
    height: 40px;
    max-height: 40px;

    &.disabled {
      &:hover {
        border-left-color: #f7f7f7;
        background: #f7f7f7;
      }
    }

    &::before {
      background: none;
    }

    &:hover {
      background: #eee;
      border-left-color: #366CD9;
    }
  }
}

.sem_TableCardItem .C-FormField--Checkbox {
  margin: 0;
}

.sem_ScrollableCardItem--left-side .C-FormField--Checkbox {
  margin: 0;
}

.sem_mscCheckbox .C-FormField--Checkbox {
  margin: 0;
  justify-content: right;
}

.sem-SetDatePicker {
  .C-FormField__Label.isErrored { color: #333 }
  .C-FormField--Input.isErrored { border-color: #B3B4B3; }
}

.sem-RosterContainer {
  align-items: center;

  .sem-RosterTitle {
    font-weight: 700;
    color: #888;
  }

}

.sem_DropdownMenuItem--action {

  &.divider {
    padding-bottom: 14px;
    border-bottom: 1px solid #d6d6d6;
  }
}

.sem_DropdownMenuItem--info {
  margin: 0 12px;
  padding: 14px 0 8px 0;
  height: 24px;
  min-height: 24px;

  &:hover {
    background: #fff;
    cursor: default;
  }

  &.divider {
    padding-bottom: 14px;
    border-bottom: 1px solid #d6d6d6;
  }
}

.sem-NewNoteForm {
  .C-FormField--Input {
    padding: 12px;
    border: none;
    background: none;
    height: 44px;

    &::placeholder { color: #B3B3B3; }
    &:-ms-input-placeholder { color: #B3B3B3; }
    &::-ms-input-placeholder { color: #B3B3B3; }
  }
}

.sem-BpRosterTab {
  .sem-BpRosterBtn {
    display: flex;
    justify-content: space-between;
    transform: rotate(90deg) translate(72px, 76px);

    &.open {
      transform: none;
    }
  }

  .sem-BpRosterBtnClose {
    display: none;
    color: #b3b3b3;

    &:hover {
      color: #888;
      background-color: #eee;
    }

    &.open {
      display: inline-flex;
    }
  }



  .sem-BpRoster {
    display: none;

    &.open {
      display: block;
    }
  }
}

.sem-BpRosterBtnSave {
  display: none;

  &.open {
    display: inline-flex;
  }
}

.sem_NotesTab {


  .sem_NotesContainer {
    padding-left: 8px;
    padding-right: 8px;
  }

  .sem_SignNotesFilter {
    padding-left: 16px;
  }

  .sem_NotesItem {
    padding-left: 24px!important;
    padding-right: 24px!important;
  }

  .sem_NotesBtnClose, .sem_NotesBtnAdd {
    display: none;
    &.open {
      display: inline-flex;
    }
  }

  .sem_NotesBtnAdd { margin-left: auto; color: #2d80ec;}

  .sem_NotesForm {
    padding: 0;
    height: 0;
    overflow: hidden;

    &.open { display: block; }

    &.isVisible {
      padding: 0 14px 14px 14px;
      height: auto;
      overflow: visible;
      animation-duration: .3s;
      animation-name: lowerDown;
      animation-timing-function: ease-in-out;
    }
  }

  .sem_Notes {
    display: none;

    &.open {
      display: block;
      padding-top: 10px;
    }

    .C-ExpandableSection__TopRow {
      align-items: flex-start;
    }

    .sem_NotesByline {
      font-weight: 700;
      color: #888;
    }
    .sem_NotesContent {
      padding-left: 18px;
      white-space: pre-wrap;
    }
  }
}

.sem_SidebarBtnContainer {
  width: 60px;

  .C-GridContainer {
    margin-top: 12px;
    transform: rotate(90deg);

    .C-GridItem {
      display: flex;
    }

    .hidden {
      display: none;
    }
  }
}

.sem_GalleryTab {
  display: none;

  &.open {
    display: block;
    flex-basis: 41.66667%;
    max-width: 41.66667%;
  }

  .sem_GalleryBtn {
    display: flex;
    justify-content: space-between;
  }

  .sem_GalleryBtnClose {
    display: inline-flex;
    color: #b3b3b3;

    &:hover {
      color: #888;
      background-color: #eee;
    }
  }

  .sem_Gallery {
    display: none;

    &.open {
      display: block;
    }
  }
}

.pog-list-modal{
  .C-Modal__container{
    max-width: 84vw;
    padding: 0 14px;
  }
}

  .C-Modal__container {
    .sem_Modal__close {
      margin: -14px;
      padding: 8px;
      max-height: 40px;
    }
  }

.sem_ListModal {
  top: 48px;

  @media (min-width: 768px) {
    .C-Modal__container {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;
      position: relative;
      overflow-y: auto;
      max-width: 750px;
      max-height: calc(100% - 96px);
      margin: 48px;
      padding: 28px;
      z-index: 7;

      .sem_Modal__close {
        margin: -14px;
        padding: 8px;
        max-height: 40px;
      }

      .sem_ListModal-Table {
        margin: -14px -14px -14px 0;
        overflow-y: auto;
        min-height: 497px;

        .C-ListItem__item {
          border-left: none;
          padding-left: 0;

          .C-FormField--Checkbox__box {
            margin: 0 9px;
          }

          &.isSelected {
            background: #eee;
          }
        }
      }
    }
  }
}

.sem-MilestoneHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin: 0 0 8px 0;
  padding: 0;
  vertical-align: baseline;
  border-bottom: 1px solid #d6d6d6;
  color: #888;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;

  .sem_MilestoneHeader--duedate {
    width: 208px;
  }

  .sem_MilestoneHeader--phase {
    width: 195px;
  }

  .sem_MilestoneHeader--assignee {
    width: 165px;
  }
}

.sem-MilestoneCard {
  background: #f7f7f7;
  margin-bottom: 8px;
  border-left: 4px solid #f7f7f7;

  .sem-RiskDate {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
  }

  .sem-MilestoneBtnRemove {
    display: inline-flex;
    color: #b3b3b3;

    &:hover {
      color: rgb(194, 194, 194);
      background-color: #eee;
    }
  }

  &.modified {
    border-left: 4px solid #ffa000;
  }
}

.sem-MilestoneCardContainer {
  min-height: 110px;
}

.sem-MilestoneTemplateCard {
  background: #ffffff;
  margin-bottom: 8px;

  &.modified {
    border-left: 4px solid #ffa000 !important;
  }

  .slider {
  direction: rtl;
  }

  .days_out {
    padding-left:6px;
  }

  .title{
    font-size: 120%;
    padding: 0px;
  }

  .subtitle {
    font-size: 110%;
  }

  &:hover, &:focus, &:active{
    background-color: #dbdbdb;
  }

  .toolbar-Container{
    padding: 8px;
    padding-right: 12px;
    padding-left: 4px;
  }

  .sem-BlueprintMilestoneToolBar {
    margin-bottom: -30px !important;
  }

  .sem-BlueprintMilestoneEdit {
    padding-right: 0px !important;
  }

  .sem-BlueprintMilestoneDelete {
    padding-left: 0px !important;
  }

  .sem-BlueprintMilestoneRiskDate {
    margin-top: 5px !important;
  }

  .sem-BlueprintMilestoneEditDueDate {
    padding-top: 15px !important;
  }

  .sem-BlueprintMilestoneInfo {
    padding-left: 0px !important;
  }

  .C-Avatar.\--dense {
    height: 30px;
    width: 30px;
  }

  .click-pointer {
    &:hover {cursor: pointer;}
  }

  .riskDate-Span {
    overflow: hidden;
    white-space: nowrap;
    padding-right: 2px;
  }

  .dateAlign-DateFormatter {
    .C-Chip__children{
      top: 0px!important;
    }

  }
  .risk-Chip {
    margin-left: -2px;
  }
  .C-Chip.\--red {
    background: #ee1000;
    color: #fff;
  }

  .C-Chip.\--yellow {
    background: #ffc400;
    color: #000;
  }

  .C-Chip.\--green {
    background: #39af02;
    color: #fff;
  }

  .C-Chip.\--error {
    background:  #d60e00;
    color: #fff;
  }

  .C-Avatar.\--red {
    background: #ee1000;
    color: #fff;
  }

  .C-Avatar.\--green {
    background: #39af02;
    color: #fff;
  }

  .C-Avatar.\--error {
    background: #d60e00;
    color: #fff;
  }

  .italicized {
    font-style:italic;
  }

  .truncate-ellipses {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .C-DropTarget {
    width: 100%;
    height: 0;
    margin-top: 30%;
    margin-bottom: 70%;
    border-radius: 50px;
  }

  .C-DropTarget__Hover {
    border-radius: 50%;
  }

}

.sem-ScheduleTypesTab {
  overflow: hidden;


  .title-name{
    font-size: 120%;
  }

  .milestone-Button {
    margin-bottom: 8px;
  }

  .right-align {
    float: right;
  }

  .rounded {
    border-radius: 4px;
  }


  .phase-GridItem {
    border-right: 2px solid #D6D6D6;
    overflow-y: scroll;
    min-height: 300px;
  }

  .C-Autocomplete__Options {
    max-height: 190px;
  }

  .phase-Divider {
    padding-bottom: 8px;
  }

  .schedule-Divider {
    padding-top: 24px;
  }
  .save-Button {
    margin-top: 8px;
  }

}

.addMilestone-Button {
  margin-bottom: 10px;
}

.sem-MilestoneListTab {
  overflow: hidden;
  min-height: 350px;
  .title-name{
    font-size: 120%;
  }
  .C-Popover {
    float: right;
  }
  .milestone-Button {
    margin-bottom: 8px;
  }
  .phase-GridItem {
    border-right: 2px solid #D6D6D6;
    overflow-y: scroll;

  }
  .phase-Divider {
    padding-bottom: 8px;
  }
  .right-align {
    float: right;
  }
  .rounded {
    border-radius: 4px;
  }
  .form {
    padding: 8px;
    padding-top: 0px;
  }
  .save-Button {
    margin-top: 16px!important;
    margin-left: 8px;
    margin-right: 8px;
    width: 96%;
  }
}

.sem_BlueprintFormCard,
.sem_TransitionFilterCard,
.sem_SignProjectCard {
  margin-top: 8px !important;
  padding: 8px 0 !important;
  background-color: #eee !important;
  border-radius: 4px !important;
  border: 1px solid #eee !important;

  &.modified {
    border-left: 4px solid  #ffa000 !important;
  }

  .C-Input--type-label.isDisabled {
    color: var(--canvas-font-color--default);
  }

  .C-Input.isDisabled {
    color: var(--canvas-font-color--default);
  }

  .C-Checkbox.isDisabled {
    color: var(--canvas-font-color--default);
  }
}


.sem_BlueprintFormCard.C-Card{
  box-shadow: unset;
}
.sem-BlueprintSearch{
  margin: 0px !important;

}

.sem_SignProjectCard {
  .C-FormField__Label{
    &.isDisabled {
      color: #000 !important;
    }
  }
}



.sem_CreativeAssetsLabel{
  display: block;
  font-size: 12px;
  line-height: 1.2;
  color: #666;
  transition: color .3s ease;
  font-weight: 700;
}

.sem_FilterCard {
  padding: 12px 0 12px 0;
  border-radius: 4px;
  border-left: 4px solid #eee;
  background-color: #eee;
  font-size: 12px;
  line-height: 24px;

  &.modified {
    border-left-color: #ffa000;
  }
}

.sem_TransitionsList {
  width: 72px;

  &.open {
    flex-basis: 50%;
    max-width: 50%;
  }

  .sem_TransitionsListBtn {
    display: flex;
    justify-content: space-between;
    transform: rotate(90deg) translate(72px, 76px);

    &.open {
      transform: none;
    }
  }

  .sem_TransitionsListBtnClose {
    display: none;
    color: #b3b3b3;

    &:hover {
      color: #888;
      background-color: #eee;
    }

    &.open {
      display: inline-flex;
    }
  }

  .sem_TransitionWrapper {
    display: none;
    margin-top: 8px;

    &.open {
      display: block
    }
  }
}

.sem_FilterTitle {
  color: #888;
  font-weight: 700;
  text-align: right;
}

.sem_FilterChip {
  margin-bottom: 5px !important;
}

.sem-NonScopeDropArea {
  padding: 12px 12px 4px 12px;
  text-align: center;
  border: 2px dashed #d6d6d6;
  color: #888;
}

.sem-RosterPlaceholder {
  text-align: center;
  color: #888;
}

.sem-TransitionCard {
  background: #fff;
  border: 1px solid #d6d6d6;
  margin-bottom: 8px;
}

.sem-AddRosterName {
  display: inline-block !important;
}

.sem-RosterForm {
  padding-top: 5px !important;
  .C-FormField__FieldInfo {
    display: flex;
  }
}

.sem-RosterAvatar {
  margin-bottom: 0px !important;
}

.sem-RosterChip {
  margin-top: 5px !important;
}

.sem_TableItemCard {
  margin-top: 8px;
  border-left: 4px solid #f7f7f7;
  background: #f7f7f7;
  color: #666;
  font-size: 12px;

  &:hover {
    border-left-color: #366CD9;
    background: #eee;
  }

  p { color: #666}

  .sem_TableItemCard--Container {
    padding: 12px 6px;
  }

  .sem_TableItemCard--StatusChip {
    width: 120px;
  }
}

.sem-AdminUploadButton {
  margin-top: 19px !important;
}

.sem-AdminCampaigns {
  height: 100vh;
}


.sem-CampaignList {
  .scroller {
    position: relative;
    margin: 0 auto;
    height: 75vh;
    overflow-y: scroll;
  }

  .right-align {
    float: right;
  }

}

.sem-CampaignCard {
  background: #efefef;
  margin-bottom: 8px;

  .campaign-Name {
    font-size: large;
    font-weight: bold;
  }

  &:hover, &:focus, &:active{
    background-color: #dbdbdb;
  }

  .new-Campaign-Name {
    font-size: large;
    font-weight: bold;
    font-style: italic;
  }
}

.sem-SelectedCampaignCard {
  background: #2d80ec;
  margin-bottom: 8px;

  .campaign-Name {
    font-size: large;
    font-weight: bold;
  }
}

.sem-CampaignPyramidCard {
  background: #f7f7f7;
  margin-top: 10px;
  border-left: 4px solid #f7f7f7;
}


.availableCampaign-Card {
  background: #fafafa;
  margin-bottom: 8px;
}

.button-Container {
  padding-bottom: 16px;
  padding-right: 0px;
}

.card-Container {
  padding-top: 0px;
}

.right-align {
  float: right;
}

.sem-CampaignPyramidCard {
  background: #f7f7f7;
  margin-top: 10px;
  border-left: 4px solid #f7f7f7;
}

.addCampaign-Button {
  margin-right: 8px;
  margin-bottom: 16px;
}

.saveCampaign-Button {
  margin-right: 8px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.filter-Heading {
  padding-bottom: 8px;
  padding-top: 24px;
}

.sem_TransitionFilterCard--Heading {
  line-height: 24px;
  color: #888;
}

.sem_SapProjectArchivedProjects {
  padding-right: 0 !important;
}

.sem_SapProjectNew {
  padding-left: 0 !important;
}

.sem_SapProjectList {
  overflow-y: hidden;
  overflow-x: hidden;
}

.sem-sapProjectExecutionMessage {
  width: 400px;
}

.sem_FilteredTransitionList {
  max-height: 92vh;
  overflow-y: auto;
}

.sem_TableScroller--Wrapper {
  display: flex;

  .sem_TableScroller--fixed-left {
    &.standard, &.estimation, &.printVendor { width: 38vw; }
    &.irl, &.spln { width: 34vw; }
    &.spVm, &.ipp, &.displaySign, &.agency { width: 40vw;}
    &.separator { width: 42vw;}
    &.finalization, &.print { width: 53vw; }
    &.kitting { width: 23vw;}

    .sem_ScrollableCardItem--left-side {
      margin-top: 8px;
      background: #f7f7f7;
      border-left: 4px solid #f7f7f7;
      border-bottom: none;
      border-radius: 4px 0 0 4px;
      height: 40px;
      max-height: 40px;

      &::before {
        background: none;
      }
    }
  }

  .sem_TableScroller--right-side {
    position: relative;
    width: 100%;
    overflow: hidden;

    .C-Table.sem-Table {

      .C-TableRow {
        .C-TableData, .C-TableHeader {
          display: table-cell;
          min-width: 120px;
          max-width: 8.33333%;

          .sem_StatusChip {
            width: 120px;
            margin-right: 0;
          }
        }
      }
    }
  }

  .sem_TableScroller {
    position: relative;
    width: 100%;
    vertical-align: top;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .set-width {
      min-width: 150px;
    }
  }

  .sem_ScrollableCardItem--right-side {
    margin-top: 8px;
    background: #f7f7f7;
    border-bottom: none;
    border-radius: 0 4px 4px 0;
    width: auto;
    overflow-x: visible;
    height: 40px;
    max-height: 40px;
  }

  .sem_TableShadow--left {
    width: 6px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    border-left: 1px solid #d6d6d6;
    background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.2)),to(transparent));
    background: -o-linear-gradient(left,rgba(0,0,0,.2),transparent);
    background: linear-gradient(90deg,rgba(0,0,0,.2),transparent);
    transition: opacity .2s ease-out;

    &.visible {
      opacity: 1;
    }
  }

  .\--xs-size-1.C-TableData, .\--xs-size-1.C-TableHeader {
    min-width: 132px;
  }
}

.sem-CampaignTable {
  padding-top: 8px;
  padding-bottom: 16px;
  border-radius: 4px;

  .C-TableHeader {
    font-size: 14px;
  }
  .C-TableData {
    font-size: 14px;
    border-radius: 4px;
  }
  .button-Padding {
    margin-left: 12px;
  }
}



.sem-archivedCampaign{
  font-style: italic;
}

.sem-editCampaignRowSave {
  margin-left: 5px !important
}

.sem-editCampaignRowYear {
  margin-left: 35px !important
}

.sem-editCampaignRow {
  margin-left: 50px !important
}

.addMilestoneOptions-Icon {
  margin-right: 12px!important;
  margin-left: 5px!important;
}

.addMilestoneMenu-Card {
  background: #ffffff;
  &:hover, &:focus, &:active{
    background-color: #dbdbdb;
  }
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 3px;
}

.addMilestoneMenu-Card--Disabled {
  color: #999;
  &:hover, &:focus, &:active{
    background-color: #ffffff;
  }
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 3px;
}

.sem_ProductHighlights--ListItem {
  padding: 8px;
  font-size: 12px;
  line-height: 1;
  color: #888;

  .sem_SpecTitle {
    padding-right: 6px;
    font-weight: 700;
  }
}

.sem_MscFacetsRadio {
  .C-InputRadio__Option {
    margin: 8px 0 32px 0;

    &:last-of-type {
      margin-bottom: 8px;
    }
  }
}
.C-GridContainer .sem_DepartmentGroupRow {
  margin-top: -10px;
  margin-bottom: -6px;
}

.sem_Fade {
  opacity: 0.15;
}

.scheduleTemplateDropdown {
  .hc-pv-dense{padding-top: 11px !important;}

}

.sem_SignTypeCount{
  width: 70px;
  height: 50px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  &--green {
    background-color: #39af02;
    color: #FFF;
    margin-left: 10px;
    margin-right: 7px;
  }
  &--yellow {
    background-color: #ffc400;
    color: #000;
    margin-left: 10px;
    margin-right: 7px;
  }
  &--red {
    background-color: #ee1000;
    color: #FFF;
    margin-left: 10px;
    margin-right: 7px;
  }
  &--blue {
    background-color: #303f9f;
    color: #FFF;
    margin-left: 10px;
    margin-right: 7px;
  }
  &--grey {
    background-color: #888;
    color: #FFF;
    margin-left: 10px;
    margin-right: 7px;
  }
}

.sem_DashboardSummary {

  background: rgb(201, 200, 200);

  .sem-RosterTitle {
    min-width: 185px;
  }

  .sem_DashboardProjectManagerSectionName {
    font-weight: bold;
    margin-left: 15px;
  }

  .sem_DashboardProjectManagerDivider {
    .C-Divider__line {
      background-color: #666;
      margin-top: 6px;
      margin-bottom: 3px;
      height: 50px;
    }
  }

  .sem_DashboardProjectManagerCount {
    width: 60px;
    height: 60px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    &.C-GridItem {
      padding-top: 20px;
    }
    &--green {
      background-color: var(--canvas-border-color--success);
      color: #FFF;
      margin-left: 10px;
      margin-right: 7px;
    }
    &--yellow {
      background-color: var(--canvas-background-color--alert);
      color: #000;
      margin-left: 10px;
      margin-right: 7px;
    }
    &--red {
      background-color: var(--canvas-background-color--red);
      color: #FFF;
      margin-left: 10px;
      margin-right: 7px;
    }
    &--blue {
      background-color: var(--canvas-background-color--indigo);
      color: #FFF;
      margin-left: 10px;
      margin-right: 7px;
    }
    &--grey {
      background-color: #888;
      color: #FFF;
      margin-left: 10px;
      margin-right: 7px;
    }
    &--white {
      background-color: #FFF;
      color: #000;
      margin-left: 10px;
      margin-right: 7px;
    }
  }
}



.sem_DashboardError {
  color: #ee1000;
}

.sem_DashboardSection {
  background-color: #eee;

  &:hover {
    cursor: pointer;
  }
}

.sem_DashboardSectionTitle {
  font-weight: bold;
  color: #666;
}

.sem_MilestoneOwnerChip {
  background-color: #666;
  color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  padding-top: 8px;
  line-height: 16.8px;
}

.sem_HealthIcon {

  border-radius: 50%;
  height: 30px;
  width: 30px;

    &--green {
      background-color: #39af02;
    }
    &--yellow {
      background-color: #ffc400;
    }
    &--red {
      background-color: #ee1000;
    }
    &--blue {
      background-color: #303f9f;
    }
    &--grey {
      background-color: #888;
    }
}

.sem_DashboardLink {
  text-decoration: underline !important;
}

.sem_DashboardTable {
  .C-TableHead { text-transform: uppercase; }

  .sem_Table--IconHeader {
    min-width: 24px;
  }

  .sem_MilestoneDueDate {
    &--green {
      background-color: #39af02;
    }
    &--yellow {
      background-color: #ffc400;
      font-weight: bold;
    }
    &--red {
      background-color: #ee1000;
      font-weight: bold;
    }
    &--blue {
      background-color: #303f9f;
      font-weight: bold;
    }

    &--grey {
      background-color: #888;
      font-weight: bold;
    }
  }

  .sem_DashboardLink {
    text-decoration: underline !important;
  }

  .sem_HealthIcon {

    border-radius: 50%;
    height: 30px;
    width: 30px;

      &--green {
        background-color: #39af02;
      }
      &--yellow {
        background-color: #ffc400;
      }
      &--red {
        background-color: #ee1000;
      }
      &--blue {
        background-color: #303f9f;
      }
      &--grey {
        background-color: #888;
      }
  }


  .C-TableRow.\--clickable,
  .sem_DashboardTableCardItem {
    margin-top: 8px;
    background: #f7f7f7;
    border-left: 4px solid #f7f7f7;
    border-bottom: none;
    border-radius: 4px;
    align-items: center;

    &.disabled {
      &:hover {
        border-left-color: #f7f7f7;
        background: #f7f7f7;
      }
    }

    &::before {
      background: none;
    }

    &:hover {
      background: #eee;
    }
  }
}

.sem_DashboardTableCardItem .C-FormField--Checkbox {
  margin: 0;
}


.sem_PerformanceDashboardSummaryCard {
  background-color: rgb(244, 244, 244);
  height: 500px;
  margin-top: 20px;

  .sem_PieChartCard {
    max-height: 182px;
  }

  .sem_PerformancePieChart {
    width: 118px;
    height: 90px;
  }

  .sem_PieChartTitle {
    font-weight: bold;
    text-align: center;
  }

  .sem_BarChartCard {
    font-weight: bold;
    text-align: center;
  }

  .C-GridContainer .sem_BarChart {
    padding: 0;
  }

  .C-GridContainer .sem_BarChartTitle {
    padding-bottom: 0;
    padding-top: 30px;
  }

  .C-GridContainer .sem_BarChartLabelGroup {
    margin-top: -30px;
    height: 60px;
    margin-left: 34px;
  }

  .C-GridContainer .sem_BarChartLabelText {
    max-width: 55px;
    padding: 0;
  }
}

.C-FormField {

  .C-Chip{
    font-weight: 700;
    .fa-check-circle{
      color: #366cd9
    }
  }

}

.sem_FormChips {
  display: flex;
  align-items: baseline;
  .C-FormField {
      width: unset;
  }
}
.C-FormChip__input:not(:checked)+.C-Chip {
  background: #d6d6d6;
}



.sem_Overview {

  .sem_StatusChip {
    min-width: 96px;
  }

  .C-Chip {
    font: inherit;
    font-size: 14px;
  }
  .sem_OverviewTitle {
    font-weight: 700 !important;
  }
}

.sem_OverviewTitle {
  font-weight: 700;
  color: #333 !important;
}

.sem_Searchbox {
    display: flex;
    flex-direction: row;
    .C-FormField--Input {

      border-top-right-radius: 0pt;
      border-bottom-right-radius: 0pt;
    }
    .C-Button {
      border-top-left-radius: 0pt;
      border-bottom-left-radius: 0pt;
    }
}

.sem_BlueprintTab {
  &:disabled {
    font-style: italic!important;
    color: #9b9b9b;
    &:hover {
      background: transparent;
    }
  }
}

.sem_ProjectTab {
  &:disabled {
    font-style: italic!important;
    color: #9b9b9b;
    &:hover {
      background: transparent;
    }
  }
}

.sem_ProjectKitCard .C-FormField__Label.isDisabled {
  color: #333 !important;
}

.sem_bulkEditModal .C-Modal__container {
  max-width: 80vw;
}

.sem_bulkEditSave {
  margin-top: 12px;
  flex-direction: row-reverse;
}

.sem_bulkEditInner {
  max-height: 630px;
  overflow-y: auto;
  overflow-x: hidden;
}

.sem_CampaignRow [data-test="prevButton"],
.sem_CampaignRow [data-test="nextButton"],
.sem_CampaignRow .C-FormField__Label {
  display: none;
}

.sem_CampaignRow {
  font-style: italic;
  border-radius: 4px;

  &.modified {
    border-left: 4px solid #ffa000 !important;
  }
}

.sem_CampaignRow .C-DatePicker__MonthIcons {
  top: 6px;
}

.sem_KitScenarioModal{
  .C-Modal__container{
    max-width: 38vw !important;
  }
}

.sem_AppBar {
  padding-top: 5px !important;

  .subheading {
    color: #fff;
  }

  .sem_Button {
    font-weight: 700 !important;
  }

  .sem_IconMenu {
    transition: background .3s ease,color .3s ease,border-radius .3s ease;
    z-index: 2;

    .C-Button {
      border-radius: 0;

      &:hover {
        background-color: #666;
      }
    }
  }

  .sem_MenuItem {
    transition: background .3s ease,color .3s ease,border-radius .3s ease;
    z-index: 2;

    &:hover {
      background-color: #666;
    }

    .C-Button {
      height: 48px;

      &:hover {
        background-color: #666 !important;
      }
    }

    .C-DropdownMenu {
      border-radius: 0 !important;
      background-color: #666;
      border: 0;
      margin: 0;
    }

    .C-DropdownMenuItem__item {
      background-color: #666 !important;
      border-radius: 0px !important;
      color: #f7f7f7!important;
      font-size: 14px !important;
      font-weight: 700 !important;
      text-transform: uppercase;
    }

    .C-DropdownMenuItem__item:hover {
      background-color: #888 !important;
      color: #e6e6e6;
    }
  }
}

.C-Chip.\--red {
  background: #ee1000;
  color: #fff;
}

.C-Chip.\--blue {
  background: #303f9f;
  color: #fff;
}

.C-Chip.\--yellow {
  background: #ffc400;
  color: #000;
}

.C-Chip.\--green {
  background: #39af02;
  color: #fff;
}
.C-Avatar.\--red {
  background: #ee1000;
  color: #fff;
}

.C-Avatar.\--yellow {
  background: #ffc400;
  color: #000;
}

.C-Avatar.\--green {
  background: #39af02;
  color: #fff;
}

.C-Avatar.\--blue {
  background: #303f9f;
  color: #fff;
}

.green {
  color: #39af02;
}

.sem_ProjectKitSummaryCard {
  margin-top: 8px;
  padding: 8px 0;
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #B3B4B3 ;
  box-shadow: unset !important;

  &.modified {
    border-left: 4px solid  #ffa000;
  }
}

.sem_ProjectKitCard {
  border: 0px !important;
  box-shadow: unset !important;
}

.sem_ProjectKitList {
  max-height: 85vh;
  overflow-x: hidden;
  .kitId {
    font-size: large;
    font-weight: bold;
  }
  .kitVendor {
    color: #666;
    text-transform: uppercase;
  }
}

.sem_ProjectKitStores {
  height: 65vh;
  overflow-x: hidden;
}
.sem_ProjectKitSignCard {
  background-color: #FFFFFF !important;
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #B3B4B3 ;
}
.sem_KitComments {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: #555555;
  font-size: small;
}

.sem_KitCommentsPopover {
  text-transform: none;
}

.sem-KitCard {
  background: #efefef;
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #B3B4B3 ;
}

.sem-KitListCard {
  background: #efefef;
  margin-bottom: 8px;

  &:hover, &:focus, &:active {
    background-color: #dbdbdb;
    cursor: pointer;
  }

  &.active {
    background-color: #dbdbdb;
    border: 1px solid #B3B4B3 ;
  }

}

.C-Popover__content.\--width-xxl {
  width: 600px;
}

.sem_KitScenarioModal{
.C-FormField--Input.isDisabled, .C-FormField--Select__Input.isDisabled, .C-FormField--Textarea.isDisabled {
  background: #b3b4b33d;
  border: 1px solid #b3b4b3;

}
}

.sem_KitScenarioTable {
  color: #000000;
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;

  .C-TableHead {
    background: #d6d6d6;
    color: #000000;
    size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .C-TableBody {
    .C-TableRow{
      &:hover{
        background-color: #efefef;
      }
    }
  }
}

.sem_assethubSymbol {
  position: relative;
      top: 5px;
      left: -15px;
  }

.addScheduleTemplate-Button {
  margin-top: 18px !important;
  margin-left: -10px !important
}
.sem-kitting-alert {
  color: #DEC802 !important;
}

.sem_ModalCloseBtn {
  margin: -14px -20px;
  padding: 8px;
  max-height: 40px;

  &.fullScreenTableEditor {
    margin: -14px -14px;
  }
}

.sem-info-alert-msg {
  color: #118f9c;
}

.sem-readonly-form-chip{
  .C-Chip {
    &:hover, &:focus {
      cursor: default !important;
      pointer-events:unset !important;
    }
  }
}

.sem-project-pog-set-dates {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.rbx-notification-settings {
  padding-top: 12px;
  padding-bottom: 12px;
}

.notification-settings-row {
  padding-bottom: 5px;
}

.rbx-kit-message {
  background-color: #FEF8C2;
  border: 2px solid #fde30c;
}

.rbx-dense-form {
  .C-Input {
    height: var(--canvas-element-height--dense)
  }
}

.rbx-settings-popover {
  .C-Popover__content.\--size-normal {
    width: 500px;
  }
}

.rbx-chip.red {
  background-color: var(--canvas-background-color--red);
  color: white;
  border: 0;
}

.rbx-chip.success {
  background-color: var(--canvas-background-color--success);
  color: white;
  border: 0;
}

.rbx-chip.green {
  background-color: var(--canvas-border-color--success);
  color: white;
  border: 0;
}

.rbx-chip.yellow, .rbx-chip.alert {
  background-color: var(--canvas-background-color--alert);
  color: white;
  border: 0;
}

.rbx-chip.indigo, .rbx-chip.blue {
  background-color: var(--canvas-background-color--indigo);
  color: white;
  border: 0;
}

.rbx-chip.orange {
  background-color: var(--canvas-border-color--error);
  color: white;
  border: 0;
}
