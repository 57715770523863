body {
  background-color: #fff;
}

.C-Layout__header,
.C-Layout__headerBackground {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 3;
  height: 48px;
  padding: 6px;
  max-width: 100%;
  z-index: 6;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
}

.C-Layout__menuButton {
  display: block;
  padding: 0 12px 0 0;
}

.C-Heading {
  &.sem-appHeading {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: white;
  }
}

.C-SideNav,
.C-Layout {
  margin-top: 48px;
}

.C-SideNav {
  background: #f7f7f7;
}

.C-Layout__body.\--include-rail {
  grid-column: 2/span 4;
}

.C-FormField__FieldInfo {
  display: none;
}

.C-Breadcrumbs__crumb {
  letter-spacing: 0.25px;

  &.--first { text-transform: none; }
}

.C-Button { letter-spacing: 1.5px; }

.C-ListItem__item {
  &.--clickable { background: none; }
}

.C-Toaster.\--top-right { top: 60px }

.C-Toaster.\--top-left { top: 60px }

.C-Tooltip:before {
  padding: 8px;
  border-radius: 4px;
}

.C-Tooltip.\--bottom {
  &:before {
    left: 50%;
    top: calc(100% + 8px);
  }
  &:hover:before { transform: translateX(-50%) scale(1); }
}

.C-Tooltip.\--bottom-right {
  &:before { top: calc(100% + 8px); }
  &:hover:before { transform: translateX(-10%) scale(1); }
}

.C-Tooltip.\--bottom-left {
  &:before {
    right: -24px;
    top: calc(100% + 8px);
  }
  &:hover:before { transform: translateX(-10%) scale(1); }
}

.C-Card.\--plane-1 {
  &.modified {
    border-left: 4px solid #366CD9;
  }
}

.C-DropdownMenuItem__item {
  color: #888;
}

.C-Autocomplete__Options {
  &.isVisible {
    min-width: 0px;
  }
}

.hc-lh-expanded {
  line-height: 1.5!important;
}

.C-Toaster__Toast.\--alert {
  &.isVisible {
    border-left: 36px solid #fde30c;
    display: flex;
    margin-bottom: 20px;
    max-width: 386px;
    position: relative;
    width: 386px;
  }
}

.C-Dialog {
  z-index: 10;
}
