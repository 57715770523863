@import "./ag-theme-rubix-mixin";

.ag-theme-rubix {
  @include ag-theme-rubix($ag-theme-rubix-default-params);

  &.sign-library-table {
    width: 100%;
    height: calc(100vh - 290px);
  }

  &.sign-import-table {
    width: 100%;
    height: calc(100vh - 290px);

    .ag-header-cell {
      &.ag-header-cell-non-filterable {
        background-color: #f7f7f7;
      }
    }

    .ag-ltr {
      .ag-header-select-all {
        margin-right: 0;
        margin-left: 0px;
      }
    }
  }

  &.blueprint-project-list-table {
    width: 100%;
    height: 60vh;

    &.full-screen {
      height: 85vh;
    }
  }

  &.project-sign-list-table {
    width: 100%;
    height: calc(100vh - 377px);

    &.full-screen {
      height: 85vh;
    }

    .C-GridContainer.\--spacing-normal {
      &.sem_StandardForm {
        &.error_messaging_enabled {
          &.wide-form {
            width: 400px;
          }
          &.toggle_editor {
            .C-FormField--Toggle {
              color: #666;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  &.pog-table {
    width: 100%;
    height: 60vh;

    .ag-ltr {
      .ag-header-select-all {
        margin-right: 0;
        margin-left: 0px;
      }
    }
  }

  &.kit-sign-list-table {
    width: 100%;
    max-height: 40vh;
  }

  &.dashboard-table {
    width: 100%;
    height: 60vh;
    .C-GridContainer.\--spacing-normal {
      &.sem_StandardForm {
        &.error_messaging_enabled {
          &.wide-form {
            width: 400px;
          }
          &.toggle_editor {
            .C-FormField--Toggle {
              color: #666;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  &.end-date-table {
    width: 100%;
    height: 65vh;
  }

  &.notifications-table {
    width: 100%;
    height: calc(100vh - 220px);
  }

  &.standard-sign-table {
    width: 100%;
    height: calc(100vh - 210px);
  }

  &.add-on-expenses-table {
    width: 100%;
    height: calc(100vh - 377px);
    .ag-ltr {
      .ag-header-select-all {
        margin-right: 0;
        margin-left: 0px;
      }
    }
    .ag-center-cols-viewport {
      overflow: auto;
    } 
  }

  .ag-header {
    margin-bottom: 8px;
    border-bottom: 1px solid #d6d6d6;
  }

  .ag-header-cell {
    color: #888;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;

    &.required-field {
      .ag-header-cell-text {
        color: #b85300;
        font-weight: 500;
      }
    }
  }

  .ag-pinned-left-header, .ag-pinned-left-cols-container {
    border-right: 1px solid #d6d6d6;
  }

  .ag-ltr {
    .ag-header-select-all {
      margin-right: 0;
      margin-left: 28px;
    }
  }

  .ag-row {
    border-bottom: solid 8px #fff;
    background-color: #f7f7f7;
  }

  .ag-full-width-row {
    .ag-cell-wrapper {
      &.ag-row-group {
        background: #e6e6e6;
        border: 1px solid #b3b3b3;
      }
    }
  }

  .ag-row-hover {
    background-color: #f0f0f0;
  }

  .ag-cell {
    line-height: 40px;

    &.ag-cell-align-center {
      text-align: center;
    }

    &.required-field {
      background-color: #FEF7B6;
      border-bottom: 2px solid #e2ba0c;
    }

    &.has-error {
      background-color: #FFD6D6;
      border-bottom: 2px solid #FF4747;
    }
  }

  .ag-layout-auto-height, .ag-layout-print {
    .ag-center-cols-clipper, .ag-center-cols-container {
        min-height: 48px;
    }
  }

  .ag-standard-button:hover {
    background-color: #366cd933;
  }

  .actions-button-cell {
    overflow: visible;
  }

  .dropdown-menu {
    padding: 4px 6px 8px;
  }

  .ag-row {
    z-index: 0;

    &.ag-row-focus {
      z-index: 1;
    }

    &.ag-row-hover {
      z-index: 2;
    }
  }

  .ag-popup-editor {
    min-width: 224px !important;
    max-width: 400px;
    z-index: 5;
  }

  .ag-row-highlight {
    background-color: #FEF7B6;

    &.new-row {
      background-color: #f0f0f0;
    }

    &.cancelled-row {
      background-color: #FFDEC2;

      .ag-cell-modified {
        background-color: #FFDEC2;
        border-color: #FFDEC2;
      }
    }

    &.ag-row-hover {
      background-color: #FDF49B;

      &.new-row {
        background-color: #e6e6e6;
      }

      &.cancelled-row {
        background-color: #FFD2AD;

        .ag-cell-modified {
          background-color: #FFD2AD;
          border-color: #FFD2AD;
        }
        }
    }
  }

  .ag-cell-modified {
    background-color: #dce4f9;
    border-bottom: 2px solid #bacaf3;
  }

  .ag-row-pog-excluded {
    background-color: #f7f7f7;

    .ag-cell-value {
      color: #b3b3b3;

      .C-Chip.\--blue {
        background: #C0C6EC;
      }
    }
  }

  .ag-row-bold {
    font-weight: bold;
  }

  .ag-cell-inline-editing {
    background: none;
    height: 40px;
    border-color: #0000 !important;
    border-right: none;
    border-radius: 0;
    padding: 0 8px;
    box-shadow: none;

    &.pog-editor {
      padding: 0 17px !important;
    }

    .C-FormField--Toggle {
      margin: 0 0 0 9px;
      padding: 8px 0;
    }
  }

  .not-editable {
    .C-FormField--Toggle {
      &.isDisabled {
        cursor: not-allowed;
      }
    }
  }

  .C-Autocomplete {
    .C-Autocomplete__Input {
      height: 32px;
      margin-top: -2px;
    }

    .C-Autocomplete__IconArea {
      bottom: -2px;
    }

    .C-Autocomplete__Options {
      &.isVisible {
        min-width: 300px;
      }
    }
  }

  .C-FormField--Toggle {
    &.isDisabled {
      cursor: default;

      .C-FormField--Toggle__input:checked+.C-FormField--Toggle__slider {
        background: #86A7E9;
      }
    }
  }

  .C-Toggle.isDisabled {
    cursor: default;
  }

  .C-Toggle.isDisabled .C-Toggle__Input:checked+.C-Toggle__Slider {
    background: var(--canvas-background-color--success);
  }
}
