.sem_ScrollToTop {
	position: sticky;
	bottom: 30px;
	width: 58px;
	height: 58px;
	font-size: 40px;

	&.C-Button.\--icon .C-Button__innerContent {
			width: 58px;
			height: 58px;
		}
}

.sem_LoadingModal {
	.C-Modal__scrollContainer {
		overflow: hidden;
	}

	.C-Modal__container {
		width: 200px;

		.C-Spinner {
			height: 1.5em;
			width: 1.5em;
		}
	}
}

.sem-hover-pointer {
	&:hover {
		cursor: pointer;
	}
}

.sem-badge-hidden {
  visibility: hidden;
}

.sem-notification-bell {
  visibility: visible !important;
}
