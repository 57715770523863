.sem_ProjectSignListTab {

	.table-editor-modal {
		.C-Modal__container {
			min-width: 96vw;
		}
	}

	.sign-group-modal {
		.C-Modal__container {
			max-width: 50vw;
			padding: 0 14px;
		}
	}

	.sign-notes-modal {
		.C-Modal__container {
			max-width: 50vw;
			padding: 0 14px;
		}

		.sem_NotesItem {
			padding-left: 24px!important;
			padding-right: 24px!important;
		}

		.sem_Notes {
			display: none;

			&.open {
				display: block;
				padding-top: 10px;
			}

			.C-ExpandableSection__TopRow {
				align-items: flex-start;
			}

			.sem_NotesByline {
				font-weight: 700;
				color: #888;
			}
			.sem_NotesContent {
				padding-left: 21px;
				white-space: pre-wrap;
			}
		}
	}

	.sem_NotesPopover {
		line-height: 1.4;
		white-space: pre-wrap;
	}

	.sem_NotesByline {
		font-weight: 700;
		color: #888;
		line-height: 24px;
	}

	.sem_DuplicateSignModal {
		.C-Modal__container {
			width: 518px;
			min-width: 518px;
			padding: 0 14px;

			.C-FormField{
				margin-top: 14px;
				margin-left: 14px;
			}

			.C-FormField--Input{
				width: 20%;
			}

		}

		.C-Modal__scrollContainer {
			overflow: hidden;
			width: 950px;
			min-width: 950px;
			padding: 0 14px;
		}
	}
}

.sign-import-modal {
  .C-Modal__container {
    min-width: 96vw;
  }
}
