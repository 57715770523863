.sem_SignDetailsContainer {
	.sign-info-status {
		margin-top: -42px;
	}

	.sem_MessagingSection {
		.sem_MppChip {
			padding-top: 20px;
		}
		.sem_MppError {
			display: block;
			visibility: hidden;
			font-size: 12px;
			line-height: 1;
			margin-top: 4px;
			min-height: 12px;
			color: #b85300;

			&.isVisible {
				visibility: visible;
			}
		}
	}

}
.sem_SignNotesContainer{
	.C-FormField--Textarea {
		overflow: scroll;
       
	}


}
